import { makeStyles } from "@material-ui/core";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BodyHeader from "../../../components/bodyheader/BodyHeader";
import VCPButton from "../../../components/button/VCPButton";
import ContentCard from "../../../components/contentcard/ContentCard";
import {
  GenaralLayout,
  GenaralLayoutSidebarBottom,
  GenaralLayoutSidebarUpper,
  GeneralLayoutBody,
  GeneralLayoutSidebar,
} from "../../../components/genrallayout/GenaralLayout";
import Layout from "../../../components/layout/Layout";
import { FaRegFolderOpen, FaCamera, FaGoogleDrive, FaDropbox, FaInstagram, FaList, FaTrash } from "react-icons/fa";
import FolderIcon from "@mui/icons-material/Folder";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  addContent,
  checkedSingleContent,
  contentMoveToFolder,
  deleteContent,
  deleteMultipleContent,
  flagContentOff,
  getContentById,
  getContentFromGoogleDrive,
  unCheckedSignleContent,
  pickingDataFromGoogle,
} from "../../../redux/actions/Content";
import All from "../components/All";
import Images from "../components/Images";
import Videos from "../components/Videos";
import { addContentInPlaylist, playlistFlagOff } from "../../../redux/actions/Playlist";
import AddContentModel from "../../../components/addcontent/AddContentModel";
import toast from "react-hot-toast";
import { useMediaQuery } from "react-responsive";
import { editFolder, folderDelete, folderFlagOff, getFolders } from "../../../redux/actions/FileFolder";
import Folders from "../components/Folders";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModel from "../../../components/confirmmodel/ConfirmationModel";
import Circularbar from "../../../components/circularbar/Circularbar";
import { checkedContent, unCheckedContent } from "../../../redux/actions/Content";
import { baseImg } from "../../../api/endpoints";
import Previewer from "../components/Previewer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { DocumentScanner } from "@mui/icons-material";
import useDrivePicker from "react-google-drive-picker";
import CardView from "../Widgets/CardView";
import CreateWidgetModel from "../components/CreateWidgetModel";
import Widgets from "../components/Widgets";
import RedesignUserProfileComponent from "../../../components/userprofile/RedesignUserProfileComponent";
// import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => {
  return {
    topHeading: {
      display: "flex",
      gap: "10px",
      margin: "0 7px",
      // padding:'10px'
      // border:'1px solid black',
      [theme.breakpoints.up("lg")]: {
        margin: "10px 7px",
      },
    },
    headingIcon: {
      width: "56px",
      height: "52px",
      background: "rgba(1, 106, 254, 0.1)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: 40,
        height: 36,
        borderRadius: "10px",
        transform: "translate(-10px,10px)",
      },
    },
    btnGroups: {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0",
    },
    bodyContent: {
      minHeight: "75vh",
      width: "100%",
    },
    bodyContentHeader: {
      height: "6vh",
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bodyCardWaraper: {
      margin: "20px auto",
      maxHeight: "60vh",
      overflowY: "auto",
      width: "100%",
      // border: "1px solid red",
      // display: "flex",
      // gap: "5px",
      // flexWrap: "wrap",
      // [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column-reverse",
      // },
    },

    layoutBody: {
      minHeight: "50vh",
      width: "55vw",
      padding: "30px 20px 0 20px",
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        marginTop: "40px",
      },
    },
    layoutSidebar: {
      minHeight: "80vh",
      width: "25vw",
      margin: "0 8px",
      background: "#FFFFFF",
      borderRadius: "10px",
      boxShadow: "1px 1px 5px 5px rgba(0,0,0,0.1)",
      // position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    layoutSidebarMidBanner: {
      // position: "absolute",
      background: "linear-gradient(30.84deg, #016AFE 61.34%, rgba(1, 163, 254, 0.53) 100%)",
      borderRadius: "0px",
      transform: "matrix(1, 0, 0, -1, 0, 0)",
      minHeight: "10vh",
      width: "100%",

      // [theme.breakpoints.up('lg')]:{
      //   top:'50vh'
      // },
      // [theme.breakpoints.down('md')]:{
      //   top:'40vh'
      // }
    },
    layoutSidebarUpperBox: {
      minHeight: "35vh",
      width: "25vw",
      // border:'1px solid black',
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    layoutSidebarBottomBox: {
      minHeight: "35vh",
      width: "25vw",
      // border: '1px solid black',
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // overflow: 'auto',
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
    },
    layoutSidebarUpperBoxInside: {
      minHeight: "35vh",
      width: "23vw",
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
      },
    },
    layoutSidebarBottomBoxInside: {
      minHeight: "30vh",
      width: "23vw",
      // border: '1px solid green',
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        transform: "translateX(30px)",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    fileMenuItems: {
      height: "95%",
      width: "90%",
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      // border:'1px solid black',
      [theme.breakpoints.up("xl")]: {
        gap: "5px",
      },
    },
  };
});
const components = {
  all: All,
  images: Images,
  videos: Videos,
  widgets: Widgets,
};
function UserFile({ socketRef, user }) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const [openPicker, data, authResponse] = useDrivePicker();
  const { content, isDeleting, isDeleted, isContentAddedInContent, isContentAdding } = useSelector((state) => state.Content);
  const { screens } = useSelector((state) => state.Screen);
  const { playlists, isContentAdded } = useSelector((state) => state.Playlist);
  const { currentFolder, allFolders, userFolders, contentFolders, files, isFolderCreated, isFolderDeleted, fileLength, userInfo } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      userFolders: state.FileFolder.userFolders.filter((f) => f.parent === "root" && f?.folderFor === "content"),
      allFolders: state.FileFolder.userFolders,
      contentFolders: state.FileFolder.userFolders.filter((f) => f?.folderFor === "content"),
      // files: state.Content.content.filter((c) => c.folderId === "root" && c?.subuser?._id==params.id),
      files: state.Content.content.filter((c) => c?.subuser?._id == params.id),
      isFolderCreated: state.FileFolder.isFolderCreated,
      isFolderDeleted: state.FileFolder.isFolderDeleted,
      fileLength: state.Content.content.filter((c) => c?.subuser?._id == params.id).length,
      userInfo: state.User.users.filter((u) => u._id == params.id),
    }),
    shallowEqual
  );
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playlistId, setPlaylistId] = useState("");
  const [ids, setIds] = useState("");
  const [folderVal, setFolderVal] = useState("");
  const [contentObj, setContentObj] = useState({});
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [browse, setBrowse] = useState(false);
  const [openMoveModel, setOpenMoveModel] = useState(false);
  const [file, setFile] = useState([]);
  const [key, setKey] = useState("all");
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [openFolderEditModel, setOpenFolderEditModel] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [viewValue, setViewValue] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openBulkDeleteModel, setOpenBulkDeleteModel] = useState(false);
  const [previewContentUrl, setPreviewContentUrl] = useState({ url: "", type: "", widget: "" });
  const [openPreviewContentModel, setOpenPreviewContentModel] = useState(false);
  const [openWidget, setOpenWidget] = useState(false);
  const [datas, setData] = useState([]);
  const Component = components[key];
  useEffect(() => {
    dispatch(getContentById(params.id));
    dispatch(getFolders(params.id));
  }, [params.id]);
  useEffect(() => {
    if (isContentAdded) {
      toast.success(t("view.userFile.contentAdded"));
      const s = screens.find((c) => c?.playlist?._id === playlistId);
      console.log(s);
      if (s !== undefined || s !== null) {
        emitOnAddContentInPlaylist(s?.mac);
      }
      dispatch(playlistFlagOff());
    }
    if (isFolderCreated) {
      toast.success(t("view.userFile.folderCreated"));
      dispatch(folderFlagOff());
    }
    if (isFolderDeleted) {
      toast.success(t("view.userFile.folderDeleted"));
      dispatch(folderFlagOff());
    }
    if (isContentAddedInContent) {
      toast.success(t("view.userFile.contentAddedSuccess"));
      dispatch(getContentById(params.id));
      dispatch(flagContentOff());
    }
    if (isDeleted) {
      toast.success(t("view.userFile.contentDeleted"));
      dispatch(flagContentOff());
    }
  }, [isContentAdded, isFolderCreated, isFolderDeleted, isContentAddedInContent, isDeleted]);
  useEffect(() => {
    document.addEventListener("fullscreenchange", function (e) {
      if (!document.fullscreenElement) {
        setPreviewContentUrl({ url: "", type: "" });
        setOpenPreviewContentModel(false);
      }
    });
  }, []);
  useEffect(() => {
    //  console.log(data);
  }, []);
  const emitOnAddContentInPlaylist = (mac) => {
    if (socketRef) {
      socketRef?.current.emit("content_added", { detail: { mac: mac } });
    }
  };
  const onAddPlaylist = (contentObject) => {
    setContentObj({ ...contentObject });
    setOpenPlaylist(true);
  };
  const addInPlaylist = () => {
    // console.log('from file add in playlist=>',contentObj)
    dispatch(addContentInPlaylist({ id: playlistId, value: contentObj.id }));
    setOpenPlaylist(false);
  };
  const fileUpload = (e) => {
    const file = e.target.files;
    const fileArray = Array.from(file);
    const includeNames = fileArray.map((name) => name.name);
    const includedItems = content.filter((item) => includeNames.some((name) => name === item.name));
    const cutting = fileArray.filter((i) => !includedItems.map((name) => name.name).some((name) => name === i.name));
    // console.log(includedItems, cutting)
    if (includedItems.length <= 0) {
      // console.log('step-1', includedItems, cutting)
      const form = new FormData();

      for (let key in file) {
        form.append("content", file[key]);
      }
      form.append("user", params.id);
      form.append("subuser", params.id);
      form.append("role", "user");
      form.append("folderId", currentFolder);
      // }
      dispatch(addContent(form));
    } else {
      toast.success(t("view.userFile.similarContentRemoved"));
      // console.log('step-2', includedItems, cutting)
      const form = new FormData();
      for (let key in file) {
        form.append("content", cutting[key]);
      }
      form.append("user", params.id);
      form.append("subuser", params.id);
      form.append("role", "user");
      form.append("folderId", currentFolder);
      // }
      dispatch(addContent(form));
    }

    setBrowse(false);
  };
  const fileUploadDragDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files;
    const fileArray = Array.from(file);
    const includeNames = fileArray.map((name) => name.name);
    const includedItems = content.filter((item) => includeNames.some((name) => name === item.name));
    const cutting = fileArray.filter((i) => !includedItems.map((name) => name.name).some((name) => name === i.name));
    if (includedItems.length <= 0) {
      // console.log('step-1', includedItems, cutting)
      const form = new FormData();

      for (let key in file) {
        form.append("content", file[key]);
      }
      form.append("user", params.id);
      form.append("subuser", params.id);
      form.append("role", "user");
      form.append("folderId", currentFolder);
      // }
      dispatch(addContent(form));
    } else {
      toast.success(t("view.userFile.similarContentRemoved"));
      console.log("step-2", includedItems, cutting);
      const form = new FormData();
      for (let key in file) {
        form.append("content", cutting[key]);
      }
      form.append("user", params.id);
      form.append("subuser", params.id);
      form.append("role", "user");
      form.append("folderId", currentFolder);
      // }
      dispatch(addContent(form));
    }
    setBrowse(false);
  };
  const onDelete = (id) => {
    setOpenDeleteModel(true);
    setIds(id);
  };
  const onConfirmDeleteFile = () => {
    dispatch(deleteContent(ids));
    setOpenDeleteModel(false);
  };
  const onMove = (id) => {
    setIds(id);
    setOpenMoveModel(true);
  };
  const moveOnFolder = () => {
    setOpenMoveModel(false);
    setFolderVal("");
    dispatch(contentMoveToFolder({ id: ids, folderId: folderVal }));
  };
  const onFolderDelete = (id) => {
    const childrenfiles = content.filter((c) => c.folderId === id);
    const childrenfolder = allFolders.filter((f) => f.parent === id);
    console.log(childrenfolder);
    if (childrenfiles.length == 0 && childrenfolder.length == 0) {
      setIds(id);
      setOpenCreateModel(true);
    } else {
      toast.success(t("view.userFile.folderContainsContent"));
    }
  };
  const onConfirm = () => {
    setOpenCreateModel(false);
    dispatch(folderDelete(ids));
  };
  const onFolderEdit = (id, name) => {
    setIds(id);
    setFolderName(name);
    setOpenFolderEditModel(true);
  };
  const onConfirmFolderEdit = () => {
    dispatch(editFolder(ids, folderName));
    setOpenFolderEditModel(false);
  };
  const onCheckedContent = () => {
    dispatch(checkedContent(currentFolder));
    setIsChecked(false);
  };
  const onUncheckedContent = () => {
    dispatch(unCheckedContent(currentFolder));
    setIsChecked(true);
  };
  const onCheckedSingleContent = (id) => {
    setIsChecked(false);
    dispatch(checkedSingleContent(id));
  };
  const onUncheckedSingleContent = (id) => {
    setIsChecked(true);
    dispatch(unCheckedSignleContent(id));
  };
  const onGetCheckedContent = () => {
    setOpenBulkDeleteModel(true);
  };
  const onBulkDeleteFileConfirm = () => {
    setOpenBulkDeleteModel(false);
    const _checkedList = content.filter((c) => c.checked === true);
    const _selectedContent = [];
    playlists.forEach((playlist) => {
      _selectedContent.push(...playlist.content);
    });
    const __gettingWithoutNull = _selectedContent.filter((_content) => _content.content !== null);
    const __gettingIdFromCheckedContent = _checkedList.map((_content) => _content.id);
    const __gettingUsingContent = __gettingWithoutNull.filter((item) => __gettingIdFromCheckedContent.some((id) => id === item.content._id));
    // console.log(__gettingUsingContent)
    if (__gettingUsingContent.length > 0) {
      toast.success(t("view.userFile.contentUsingInPlaylist"));
    } else {
      dispatch(deleteMultipleContent(_checkedList));
    }
  };
  const onPreview_content = (previewContentObject) => {
    setPreviewContentUrl({
      url: previewContentObject.url,
      type: previewContentObject.contentType,
      embed: previewContentObject.embed,
      widget: previewContentObject.widget,
    });
    setOpenPreviewContentModel(true);
    handle.enter();
  };
  const onGoogleDrive = () => {
    // console.log('from onGoogleDrive')
    openPicker({
      clientId: "990992798551-33bf98q6m50ujhvp7l71scf282i0mlvu.apps.googleusercontent.com",
      developerKey: "AIzaSyCJYi1VdZgSEBqjgFgIl_WErXZdSHRDfJY",
      // customScopes: ['https://www.googleapis.com/auth/drive'],
      // redirect_uri:'http/localhost:3000',
      // clientSecret:'',
      //  developerKey:'AIzaSyDjSh4kBWyvJ1MbDmdb4QcFK_JTPSUQQ3w',
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "loaded") {
          setBrowse(false);
        }
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          dispatch(pickingDataFromGoogle());
        }
        let objFiles = [];
        data?.docs?.forEach((d) => {
          objFiles.push({
            id: d?.id,
            name: d?.name,
            url: d?.embedUrl,
            //  type:d?.type,
            embed: "embed",
            role: "user",
            user: params.id,
            subuser: params.id,
            folderId: currentFolder,
          });
        });
        // console.log(objFiles)
        dispatch(getContentFromGoogleDrive(objFiles));
      },
    });
  };
  const onDropboxFiles = (files) => {
    let objFiles = [];
    files.forEach((f) => {
      objFiles.push({
        id: f.id,
        name: f.name,
        type: f.link.substring(f.link.lastIndexOf(".") + 1).split("?")[0],
        url: f.link.replace("?dl=0", "?dl=1"),
        embed: "embed",
        role: "user",
        user: params.id,
        subuser: params.id,
        folderId: currentFolder,
      });
    });
    dispatch(getContentFromGoogleDrive(objFiles));
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  return (
    <>
      <Layout title={t("view.userFile.userFiles")} user={user} playlistAddBtn={false} position={"relative"}>
        {isContentAdding && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                // left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000000000000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar height={"150px"} width={"150px"} />
            </Box>
          </>
        )}
        {isDeleting && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                top: 0,
                // left: 0,
                bottom: 0,
                right: 0,
                zIndex: 10000000000000,
                background: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Circularbar height={"150px"} width={"150px"} />
            </Box>
          </>
        )}

        <RedesignUserProfileComponent user={userInfo} page={"Content"} total={fileLength} />

        <Box
          style={{
            minHeight: "80vh",
            width: isMobile ? "90vw" : "80vw",
            margin: "20px auto",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            // position:'relative'
          }}>
          <Box className={classes.layoutSidebar}>
            <Box className={classes.layoutSidebarUpperBox}>
              <Box className={classes.layoutSidebarUpperBoxInside}>
                <Box className={classes.topHeading}>
                  <Box className={classes.headingIcon}>
                    <img src="/images/searchColorIcon.png" />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      // border:'1px solid black',
                      width: "75%",
                    }}>
                    <TextField
                      type={"text"}
                      label={t("view.userFile.search")}
                      value={search}
                      variant={"standard"}
                      onChange={(e) => setSearch(e.target.value)}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: {
                      xl: "40px 0",
                      lg: "20px 0",
                      md: "20px 0",
                      sm: "20px 0",
                      xs: "20px 0",
                    },
                  }}>
                  <VCPButton
                    name={t("view.userFile.uploadFiles")}
                    height={"40px"}
                    width={"366px"}
                    size={"large"}
                    variant={"contained"}
                    backgroundColor={"#016AFE"}
                    onClick={() => setBrowse(true)}
                    disable={user.isMember ? (user?.previlliages?.content?.canCreate ? false : true) : false}
                  />
                </Box>
                <Box
                  sx={{
                    margin: {
                      xl: "40px 0",
                      lg: "20px 0",
                      md: "20px 0",
                      sm: "20px 0",
                      xs: "20px 0",
                    },
                  }}>
                  <p className="lorem-file">{t("view.userFile.lorem")}</p>
                </Box>
                <Box className={classes.btnGroups}>
                  <VCPButton
                    name={t("view.userFile.apps")}
                    height={"40px"}
                    width={"48%"}
                    size={"large"}
                    variant={"contained"}
                    backgroundColor={"#016AFE"}
                    onClick={() => setOpenWidget(true)}
                    disable={user.isMember ? (user?.previlliages?.content?.canCreate ? false : true) : false}
                  />

                  <VCPButton
                    name={t("view.userFile.templates")}
                    height={"40px"}
                    width={"48%"}
                    size={"large"}
                    variant={"contained"}
                    backgroundColor={"#016AFE"}
                    disable={user.isMember ? (user?.previlliages?.content?.canCreate ? false : true) : false}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.layoutSidebarMidBanner}></Box>

            <Box className={classes.layoutSidebarBottomBox}>
              <Box className={classes.layoutSidebarBottomBoxInside}>
                <Box className={classes.fileMenuItems}>
                  {/* <Link to={'/'}></Link> */}
                  <Typography variant={"caption"} className={"type-file"}>
                    {t("view.userFile.type")}
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      transform: "translateX(-8px)",
                      width: "95%",
                      margin: { xl: "5px auto", lg: "0 auto", md: "0 auto" },
                      // border:'1px solid black',
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setKey("all")}>
                    <img src={"/images/allDocColorIcon.png"} />
                    <Typography
                      variant={"caption"}
                      sx={{
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#92959E",
                      }}>
                      {t("view.userFile.all")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "25px",
                      transform: "translateX(-8px)",
                      width: "95%",
                      margin: { xl: "5px auto", lg: "0 auto", md: "0 auto" },
                      //  border:'1px solid black',
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setKey("images")}>
                    <img src={"/images/imageColorIcon.png"} />
                    <Typography
                      variant={"caption"}
                      sx={{
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#92959E",
                      }}>
                      {t("view.userFile.images")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "25px",
                      transform: "translateX(-8px)",
                      width: "95%",
                      margin: { xl: "5px auto", lg: "0 auto", md: "0 auto" },
                      //  border:'1px solid black',
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setKey("videos")}>
                    <img src={"/images/videoColorIcon.png"} />
                    <Typography
                      variant={"caption"}
                      sx={{
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#92959E",
                      }}>
                      {t("view.userFile.videos")}
                    </Typography>
                  </Box>

                  {/* 
                    <Box
                      sx={{
                        height: "25px",
                        transform: "translateX(-8px)",
                        width: "95%",
                        margin: { xl: "5px auto", lg: "0 auto", md: "0 auto" },
                        //  border:'1px solid black',
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={"/images/docColorIcon.png"} />
                      <Typography
                        variant={"caption"}
                        sx={{
                          fontFamily: "open sans",
                          fontStyle: "normal",
                          fontWeight: 700,
                          fontSize: "15px",
                          lineHeight: "20px",
                          color: "#92959E",
                        }}
                      >
                        Docs
                      </Typography>
                    </Box>
                  */}

                  <Box
                    onClick={() => setKey("widgets")}
                    sx={{
                      height: "25px",
                      transform: "translateX(-8px)",
                      width: "95%",
                      margin: { xl: "5px auto", lg: "0 auto", md: "0 auto" },
                      //  border:'1px solid black',
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}>
                    <img src={"/images/appColorIcon.png"} />
                    <Typography
                      variant={"caption"}
                      sx={{
                        fontFamily: "open sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "15px",
                        lineHeight: "20px",
                        color: "#92959E",
                      }}>
                      {t("view.userFile.apps")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <GeneralLayoutBody>
            <Box className={classes.bodyContent}>
              <Box className={classes.bodyContentHeader}>
                <BodyHeader
                  user={user}
                  view={viewValue}
                  onViewChange={() => setViewValue(!viewValue)}
                  onAddPlaylist={onAddPlaylist}
                  onMove={onMove}
                  onCheckedContent={onCheckedContent}
                  onUncheckedContent={onUncheckedContent}
                  onGetCheckedContent={onGetCheckedContent}
                  isChecked={isChecked}
                />
              </Box>
              <Box className={classes.bodyCardWaraper}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    flexWrap: "wrap",
                    margin: "10px 0",
                  }}>
                  {/* {userFolders.map((folder, index) => {
                    return (
                      <>
                        <Folders
                          key={index}
                          folder={folder}
                          onFolderDelete={onFolderDelete}
                          onFolderEdit={onFolderEdit}
                        />
                      </>
                    );
                  })} */}
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xl: "row", lg: "row", sm: "row", xs: "column" },
                    gap: "5px",
                    flexWrap: "wrap",
                    margin: "10px 0",
                  }}>
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "10px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {datas?.map((item, index) => (
                      <CardView key={index} item={item} />
                    ))}
                  </div> */}
                  <Component
                    content={files}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={(contentObject) => console.log(contentObject)}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview_content}
                    view={viewValue}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                  />
                </Box>
              </Box>
            </Box>
          </GeneralLayoutBody>
        </Box>
      </Layout>

      <CreateWidgetModel isOpen={openWidget} onTakeData={(data) => setData((prev) => [...prev, data])} onClose={() => setOpenWidget(false)} />
      {/* content preview model */}
      {openPreviewContentModel && <Previewer open={openPreviewContentModel} previewContentUrl={previewContentUrl} onClose={() => setOpenPreviewContentModel(false)} />}

      {/* content preview model end */}

      <ConfirmationModel
        openCreateModel={openCreateModel}
        title={t("view.userFile.confirmDelete")}
        onClose={() => setOpenCreateModel(false)}
        onConfirm={onConfirm}
        Icon={FaTrash}
      />
      <ConfirmationModel
        openCreateModel={openDeleteModel}
        title={t("view.userFile.confirmDelete")}
        onClose={() => setOpenDeleteModel(false)}
        onConfirm={onConfirmDeleteFile}
        Icon={FaTrash}
      />
      <ConfirmationModel
        openCreateModel={openBulkDeleteModel}
        title={t("view.userFile.confirmDelete")}
        onClose={() => setOpenBulkDeleteModel(false)}
        onConfirm={onBulkDeleteFileConfirm}
        Icon={FaTrash}
      />

      <Dialog open={openMoveModel} onClose={() => setOpenMoveModel(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("view.userFile.selectFolder")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FolderIcon
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  color: "#1977FA",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={folderVal} onChange={(e) => setFolderVal(e.target.value)} fullWidth>
                {[
                  {
                    _id: "root",
                    name: "Home",
                    folderFor: "content",
                    path: [],
                    parent: "",
                  },
                  ...contentFolders,
                ].map((folders) => (
                  <MenuItem value={folders._id}>{folders.name}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenMoveModel(false)}>
                {t("view.userFile.close")}
              </Button>
              <Button
                variant={"contained"}
                onClick={moveOnFolder}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("view.userFile.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openPlaylist} onClose={() => setOpenPlaylist(false)} maxWidth={"lg"}>
        <DialogTitle sx={{ textAlign: "center" }}>{t("view.userFile.selectPlaylist")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "40vw",
              height: "40vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Box style={{ width: "100px", height: "100px", margin: "0 auto" }}>
              <FaList
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  color: "#1977FA",
                }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <Select value={playlistId} onChange={(e) => setPlaylistId(e.target.value)} fullWidth>
                {playlists.map((playlist) => (
                  <MenuItem value={playlist._id}>{playlist.playlistName}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                gap: "10px 10px",
              }}>
              <Button
                variant={"outlined"}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                onClick={() => setOpenPlaylist(false)}>
                {t("view.userFile.close")}
              </Button>
              <Button
                variant={"contained"}
                onClick={addInPlaylist}
                sx={{
                  height: "38px",
                  width: "193px",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                }}>
                {t("view.userFile.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <AddContentModel
        browse={browse}
        onClose={(e) => setBrowse(e)}
        fileUpload={fileUpload}
        fileUploadDragDrop={fileUploadDragDrop}
        onGoogleDrive={onGoogleDrive}
        onDropboxFiles={onDropboxFiles}
        reOpen={() => setBrowse(true)}
      />

      <Dialog open={openFolderEditModel} onClose={() => setOpenFolderEditModel(false)}>
        <DialogTitle style={{ textAlign: "center" }}>{t("view.userFile.editFolder")}</DialogTitle>
        <DialogContent style={{ borderRadius: "50px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box style={{ width: "300px", height: "50px", margin: "20px auto" }}>
              <TextField type={"text"} value={folderName} onChange={(e) => setFolderName(e.target.value)} fullWidth />
            </Box>

            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => setOpenFolderEditModel(false)}
                variant={"contained"}
                color={"error"}
                sx={{
                  height: "38px",
                  width: "150px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("view.userFile.cancel")}
              </Button>
              <Button
                onClick={onConfirmFolderEdit}
                variant={"contained"}
                sx={{
                  height: "38px",
                  width: "150px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}>
                {t("view.userFile.save")}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UserFile;
