import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { FormContainer, TextFieldElement, SelectElement, SwitchElement } from "react-hook-form-mui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { updateUserProfile } from "src/redux/actions/User";
import parseApiFormErrors from "src/utils/parse-api-form-errors";

import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";

import AvatarInputFile from "./components/AvatarInputFile";
import UserAddressInput from "./components/UserAddressInput";
import UserInitValues from "./components/UserInitValues";

import { useJsApiLoader } from "@react-google-maps/api";

function RedesignUserEdit({ user }) {
  const { t } = useTranslation();
  const { user: authUser } = useSelector((state) => state.User);
  const { plans } = useSelector((state) => state.Plan);

  const planOptions = useMemo(() => plans.map((p) => ({ id: p._id, label: p.name })), [plans]);

  const { isLoaded } = useJsApiLoader({
    id: "user-edit-autocomplete",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (user.isDeleted) {
    navigate(paths.users.details.replace(":id", user._id));
  }

  const [isFormPending, setFormPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const initValues = useMemo(() => {
    const { fullname, email, formattedAddress, country, state, address1, address2, phone, acceptNewsletter, avatar, plan } = user;

    return { fullname, email, formattedAddress, country, state, address1, address2, phone, acceptNewsletter, avatar, plan: plan?._id };
  }, [user]);

  const handleSubmit = async (data) => {
    try {
      setFormPending(true);
      setFormErrors({});
      await dispatch(updateUserProfile(user._id, data));
      toast.success(t("views.userEdit.userDataSaved"));
    } catch (e) {
      if (!e.formErrors) return;

      setFormErrors(parseApiFormErrors(e.formErrors));
    } finally {
      setFormPending(false);
    }
  };

  if (!isLoaded) {
    return <>{t("views.userEdit.loading")}</>;
  }
  return (
    <FormContainer defaultValues={initValues} errors={formErrors} onSuccess={handleSubmit}>
      <fieldset disabled={isFormPending}>
        <UserInitValues initValues={initValues} />
        <Card>
          <CardHeader title={t("views.userEdit.basicDetails")} />
          <CardContent>
            <Grid container>
              <Grid xs={12} md={6}>
                <Stack spacing={3}>
                  <AvatarInputFile name="avatar" user={user} />
                  {authUser.role == "admin" && <SelectElement name="plan" label={t("views.userEdit.plan")} options={planOptions} />}
                  <TextFieldElement label={t("views.userEdit.fullName")} name="fullname" fullWidth sx={{ mr: 3 }} />
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <TextFieldElement
                      label={t("views.userEdit.emailAddress")}
                      name="email"
                      required
                      sx={{
                        flexGrow: 1,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderStyle: "dashed",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: "40px" }}>
          <CardHeader title={t("views.userEdit.editCustomer")} />
          <CardContent sx={{ pt: 0 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={12}>
                <UserAddressInput />
              </Grid>
              <Grid xs={12} md={6}>
                <TextFieldElement
                  fullWidth
                  label={t("views.userEdit.country")}
                  name="country"
                  className="Mui-disabled"
                  inputProps={{ readOnly: true, className: "Mui-disabled" }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextFieldElement
                  fullWidth
                  label={t("views.userEdit.stateRegion")}
                  name="state"
                  className="Mui-disabled"
                  inputProps={{ readOnly: true, className: "Mui-disabled" }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextFieldElement
                  fullWidth
                  label={t("views.userEdit.address")}
                  name="address1"
                  className="Mui-disabled"
                  inputProps={{ readOnly: true, className: "Mui-disabled" }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextFieldElement
                  fullWidth
                  label={t("views.userEdit.zipCodeCity")}
                  name="address2"
                  className="Mui-disabled"
                  inputProps={{ readOnly: true, className: "Mui-disabled" }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextFieldElement fullWidth label={t("views.userEdit.phoneNumber")} name="phone" />
              </Grid>
            </Grid>
            <Stack divider={<Divider />} spacing={3} sx={{ mt: 3 }}>
              <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={3}>
                <Stack spacing={1}>
                  <Typography gutterBottom variant="subtitle1">
                    {t("views.userEdit.newsletter")}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {t("views.userEdit.newsletterDescription")}
                  </Typography>
                </Stack>
                <SwitchElement color="primary" edge="start" name="acceptNewsletter" />
              </Stack>
            </Stack>
          </CardContent>
          <Stack direction={{ xs: "column", sm: "row" }} flexWrap="wrap" spacing={3} sx={{ p: 3 }}>
            <LoadingButton loading={isFormPending} type="submit" variant="contained">
              {t("views.userEdit.save")}
            </LoadingButton>
            <Button color="inherit" component={RouterLink} href={paths.users.details.replace(":id", user._id)}>
              {t("views.userEdit.cancel")}
            </Button>
          </Stack>
        </Card>
      </fieldset>
    </FormContainer>
  );
}

RedesignUserEdit.propTypes = {
  user: PropTypes.object.isRequired,
};

export default RedesignUserEdit;
