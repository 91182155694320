import { api } from "../../api/endpoints";
import {
  ADD_CONTENT_IN_PLAYLIST,
  ADD_PLAYLIST_SUCCESS,
  ADD_PLAYLIST_FAILED,
  DELETE_PLAYLIST,
  GET_ONE_PLAYLIST,
  GET_PLAYLIST_SUCCESS,
  PLAYLIST_FLAG_OFF,
  UPDATE_DURATION,
  DELETE_CONTENT_FROM_PLAYLIST,
  EDIT_PLAYLIST_NAME,
  UNCHECKED_TIME_LEBEL,
  PLAYLIST_CONETNT_SORTING,
  EDIT_PLAYLIST_DEMO_ATTRIBUTE,
} from "../constant";

export const playlistFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: PLAYLIST_FLAG_OFF,
      payload: "",
    });
  };
};
export const getPlaylists = () => {
  return async (dispatch) => {
    const playlist = await api.get(`/playlist/getplaylists`);
    if (playlist.status === 200) {
      dispatch({
        type: GET_PLAYLIST_SUCCESS,
        payload: playlist.data.playlist,
      });
    }
  };
};
export const getPlaylistsById = (id) => {
  return async (dispatch) => {
    const playlist = await api.get(`/playlist/getplaylists/${id}`);
    if (playlist.status === 200) {
      dispatch({
        type: GET_PLAYLIST_SUCCESS,
        payload: playlist.data.playlist,
      });
    }
  };
};
export const getPlaylistByUserId = (id, platform) => {
  return async (dispatch) => {
    const playlist = await api.get(`/playlist/getplaylistbyuserid/${id}/${platform}`);
    console.log("user playlist => ", playlist);
  };
};
export const addPlaylist = (obj) => {
  return async (dispatch) => {
    console.log("playlist adding=>", obj);
    try {
      const response = await api.post(`/playlist/addplaylist`, obj);
      if (response.status === 201) {
        dispatch({
          type: ADD_PLAYLIST_SUCCESS,
          payload: response.data.playlist,
        });
        return { type: ADD_PLAYLIST_SUCCESS, payload: response.data.playlist };
      } else {
        throw new Error("Failed to add playlist");
      }
    } catch (error) {
      dispatch({
        type: ADD_PLAYLIST_FAILED,
        payload: error.message,
      });
      return { type: ADD_PLAYLIST_FAILED, payload: error.message };
    }
  };
};
export const findOne = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ONE_PLAYLIST,
      payload: id,
    });
  };
};
export const addContentInPlaylist = (array) => {
  return async (dispatch) => {
    // console.log(obj)
    // dispatch({
    //   type: ADD_CONTENT_IN_PLAYLIST,
    //   payload: obj,
    // });
    // console.log('from action in plyalist=>',obj)
    // const requests = array.map((obj)=>(
    //   api.patch(
    //     `/playlist/addcontentinplaylist/${obj.id}`,
    //     {
    //       content: [{ content: obj.value }],
    //     }
    //   )
    // ));

    for (const obj of array) {
      const playlist = await api.patch(`/playlist/addcontentinplaylist/${obj.id}`, {
        content: [{ content: obj.value }],
      });
      if (playlist.status === 201) {
        dispatch({
          type: ADD_CONTENT_IN_PLAYLIST,
          payload: playlist.data.playlist,
        });
      }
      await new Promise((resolve) => setTimeout(() => resolve(), 500));
    }
    // await Promise.all(requests);

    // if (playlist.status === 201) {
    //   dispatch({
    //     type: ADD_CONTENT_IN_PLAYLIST,
    //     payload: playlist.data.playlist,
    //   });
    // }
  };
};
export const deletePlaylist = (ids) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PLAYLIST,
      payload: ids,
    });
    const playlist = await api.post(`/playlist/deleteplaylist`, { ids });
  };
};
export const updateDuration = (obj) => {
  return async (dispatch) => {
    try {
      console.log('updateDuration - input', obj);
      const response = await api.patch(`/playlist/updateduration/${obj.playlistId}`, {
        time: Math.round(obj.time * 1000),  // Convert seconds to milliseconds and round off
        contentId: obj.contentId,
        resetDuration: obj.resetDuration
      });

      console.log('updateDuration - server response', response.data);

      if (response.status === 200 && response.data.updatedDuration) {
        const updatedDuration = parseInt(response.data.updatedDuration, 10) / 1000;  // Convert milliseconds to seconds
        console.log('updateDuration - calculated duration', updatedDuration);
        
        if (!isNaN(updatedDuration)) {
          dispatch({
            type: UPDATE_DURATION,
            payload: {
              playlistId: obj.playlistId,
              contentId: obj.contentId,
              duration: updatedDuration
            },
          });
          return updatedDuration;  // Return time in seconds
        }
      }
      console.log('updateDuration - failed to update');
      return undefined;  // Return undefined in case of error
    } catch (error) {
      console.error('Error updating duration:', error);
      return undefined;
    }
  };
};
export const deleteContentInPlaylist = (obj) => {
  return async (dispatch) => {
    const updatedPlaylist = await api.patch(`/playlist/deletecontentfromplaylist/${obj.playlistId}`, { contentId: obj.contentId });
    if (updatedPlaylist.status === 201) {
      dispatch({
        type: DELETE_CONTENT_FROM_PLAYLIST,
        payload: {
          contentId: obj.contentId,
          updatedPlaylist: updatedPlaylist.data.updatedPlaylist,
        },
      });
    }
  };
};
export const editPlaylistName = (id, playlistName) => {
  return async (dispatch) => {
    const updated = await api.patch(`/playlist/editplaylistname/${id}`, {
      playlistName,
    });
    if (updated.status === 201) {
      dispatch({
        type: EDIT_PLAYLIST_NAME,
        payload: updated.data.updated,
      });
    }
  };
};
export const editPlaylistDemoAttribute = (id, checked) => {
  return async (dispatch) => {
    const updated = await api.patch(`/playlist/editplaylistdemoattribute/${id}`, {
      checked,
    });
    if (updated.status === 201) {
      dispatch({
        type: EDIT_PLAYLIST_DEMO_ATTRIBUTE,
        payload: updated.data.updated,
      });
    }
  };
};

export const unCheckedTimeLebel = (pid, cid) => {
  return async (dispatch) => {
    // alert(pid)
    dispatch({
      type: UNCHECKED_TIME_LEBEL,
      payload: { playlistId: pid, contentId: cid },
    });
  };
};
export const playlistContentSorting = (playlistId, from, to) => {
  return async (dispatch) => {
    await api.post(`/playlist/playlistsortingcontent`, {
      playlistId,
      from,
      to,
    });
    dispatch({
      type: PLAYLIST_CONETNT_SORTING,
      payload: { playlistId, from, to },
    });
  };
};
