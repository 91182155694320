import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import purifyHTML from "../../../utils/purify-html";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from "@mui/material";

import { addWidget, addContent } from "../../../redux/actions/Content";
import { FileDropzone } from "../../../components/addcontent/FileDropzone";

export default function DialogBoxCanva({ isOpen, onClose, onTakeData, isIcon }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const { user } = useSelector((state) => state.User);

  //Form fields
  const [name, setName] = useState("");
  const [embedCode, setEmbedCode] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    handleDialogClose();
  }, [isOpen, onClose]);

  const handleFileDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const handleRemoveFile = useCallback(() => {
    setFile(null);
  }, []);

  const handleDialogClose = useCallback(() => {
    setName("");
    setEmbedCode("");
    setFile(null);
  }, []);

  const handleAdd = useCallback(() => {
    const isEmbed = !!embedCode;

    if (name === "" || (!file && !embedCode)) {
      toast.error(t("views.dialogBoxPostermywall.fillRequiredFields"));
      return;
    }

    let action;

    if (isEmbed) {
      let embedHTML = purifyHTML.sanitize(embedCode, { ADD_TAGS: ["iframe"] });
      embedHTML = embedCode.replaceAll(/"/g, '\\"').replaceAll(/\n/g, '\\n').toString();
      action = addWidget({ name, type: 'widget', widgetCat: 'canva', widgetIcon: isIcon, url: '', html: embedHTML, folderId: currentFolder, user: user._id });

    } else {
      //Handle file
      const form = new FormData();
      form.append("content", file);
      form.append("user", user.isMember ? user?.user._id : user._id);
      form.append("subuser", user.isMember ? user?.user._id : user._id);
      form.append("role", user.isMember ? user?.role.split('-')[0] : user?.role);
      form.append("folderId", currentFolder);

      action = addContent(form);
    }

    dispatch(async (dispatch) => {
      await action(dispatch);
      onClose();
      return;
    });
  }, [name, embedCode, file]);

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <img src={"./images/postermywallLogo.png"} alt="Logo" width={"150px"} />
        </Box>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxPostermywall.title")}</DialogTitle>
        <DialogContent sx={{ minHeight: "30vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={2}>
              <Button href="https://www.postermywall.com/index.php?rid=1031" target="_blank" variant="contained" color="primary" underline="none">
                {t("views.dialogBoxPostermywall.loginToPostermywall")}
              </Button>
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField label={t("views.dialogBoxPostermywall.designName")} fullWidth value={name} onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12} mt={3}>
              <Typography variant="h6">{t("views.dialogBoxPostermywall.addAsEmbedCode")}</Typography>
              <Box display="flex" alignItems="center" mt={3}>
                <TextField
                  variant="outlined"
                  multiline
                  label={t("views.dialogBoxPostermywall.embedCode")}
                  fullWidth
                  value={embedCode}
                  rows={5}
                  onChange={(e) => setEmbedCode(e.target.value)}
                  disabled={!!file}
                />
              </Box>
              <Typography align="right" mt={2}>
                <Link href="https://blog.postermywall.com/design-studio/2020/7/embed-designs-anywhere" target="_blank">
                  {t("views.dialogBoxPostermywall.howToGetEmbedCode")}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("views.dialogBoxPostermywall.orUploadFile")}</Typography>
              <Box mt={4} display="flex">
                <FileDropzone
                  accept={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/webp": [],
                    "image/heic": [],
                    "image/jfif": [],
                  }}
                  caption={t("views.dialogBoxPostermywall.maxFileSize")}
                  files={[file].filter(Boolean)}
                  disabled={!!embedCode}
                  onDrop={handleFileDrop}
                  onRemove={handleRemoveFile}
                  onRemoveAll={handleRemoveFile}
                  onUpload={() => {}}
                  multiple={false}
                  maxFiles={1}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div style={{ marginLeft: "auto" }}>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxPostermywall.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxPostermywall.save")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
