import React from "react";
import { useTranslation } from "react-i18next";

const Weather = ({ temperature, pressure, humidity, description, rain, icon }) => {
  const { t } = useTranslation();

  return (
    <div className="weather-container">
      {temperature && <p> {temperature}°C</p>}
      {pressure && <p> {pressure}hPa</p>}
      {humidity && <p> {humidity}%</p>}
      {description && <p> {description}</p>}
      {rain && <p>{rain}</p>}
      {icon && <img src={icon} alt={t("weather.iconAlt")} />}
    </div>
  );
};

export default Weather;
