import React from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Box, Dialog, Popover, Button, Stack } from "@mui/material";


const SchedulerPopover = ({id, open, anchorEl, handleClose, num, time, handleSaveDate,key_}) => {
    const [tempTime, setTempTime] = React.useState(time)
    const [tempDate, setTempDate] = React.useState(time)

    const handleTimeChange = (newValue) => {
        setTempTime(newValue)
    };

    const handleDateChange = (newValue) => {
        setTempDate(newValue)
    }
    
    const handleSaveDateTime = () => {
        console.log("KLUCZ: ", key_)
        const time = tempTime.format("HH:mm")
        const date = tempDate.format("YYYY/MM/DD")
        handleSaveDate(date+" - "+time, key_)
        handleClose(num)
    }
    
  return (
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={()=>handleClose(num)}
        sx={{
            '& .MuiPaper-root': {
            padding: '20px', 
            paddingBottom:"0px",
            marginBottom: '10px',
            borderRadius:"10px",
            display: 'flex',
            flexDirection: 'column',
            },
        }}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        }}
    >   
    <Stack direction={"row"}>
        <Stack direction={"column"} sx={{}}>
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
                <DemoItem sx={{width:"93%"}}>
                    <DesktopTimePicker sx={{width:"93%"}} defaultValue={dayjs(time, 'YYYY-MM-DDTHH:mm')} onChange={handleTimeChange}/>
                </DemoItem>
                <DateCalendar minDate={dayjs()} value={dayjs(tempDate, 'YYYY-MM-DDTHH:mm')}  onChange={handleDateChange}/>
            </LocalizationProvider>
        </Stack>
        <Stack direction={"column"} sx={{gap:"10px"}}>
            <Button  variant="contained" onClick={handleSaveDateTime}>Apply</Button>
            <Button  variant="outlined" onClick={()=>handleClose(num)}>Cancel</Button>
        </Stack>

    </Stack>

    </Popover>
  )
}

export default SchedulerPopover