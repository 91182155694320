import {
    ADD_SPLITSCREEN_SUCCESS,
    GET_SPLITSCREEN_SUCCESS,
    ADD_SPLITSCREEN_FAILED,
    DELETE_SPLITSCREEN
} from "../constant";
import toast from "react-hot-toast";


const initial = {
    splitScreens: [],
    splitScreen: {},
    isSplitScreenAdded: false,
    isSplitScreenLoaded: false,
    isContentAdded: false,
}


export const SplitScreenReducer = (state = initial, action) => {
    switch (action.type) {
        case ADD_SPLITSCREEN_SUCCESS:
            toast.success("SplitScreen Added Successfully");
            return {
                ...state,
                splitScreens: [action.payload, ...state.splitScreens],
                isSplitScreenAdded: true,
            };
        case ADD_SPLITSCREEN_FAILED:
            toast.error(action.payload);
            return {
                ...state,
                isContentAdding: false,
            };
        case GET_SPLITSCREEN_SUCCESS:
            return {
                ...state,
                splitScreens: action.payload,
                isSplitScreenLoaded: true,
            };
        case DELETE_SPLITSCREEN:
            return {
                ...state,
                splitScreens: state.splitScreens.filter((splitScreen) => !action.payload.includes(splitScreen._id)),
            }
        default:
            return state;
    }
}