import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import CardVimeo from "./CardVimeo";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from "../../../redux/actions/Content";

export default function DialogBoxVimeo({ isOpen, onClose, onTakeData, isIcon }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [item, setItem] = useState({});

  const { user } = useSelector((state) => state.User);

  React.useEffect(() => {
    handleClickOpen();
  }, [isOpen, onClose]);

  const handleClickOpen = () => {
    if (isOpen) {
      setName("");
      setUrl("");
    }
    if (onClose) {
      setName("");
      setUrl("");
    }
  };

  const handleClose = () => {};

  const handleAdd = () => {
    const array = url.split("/");
    const vmId = array.pop();
    if (name === "" || url === "") {
      toast.error(t("views.dialogBoxVimeo.fieldsRequired"));
      return;
    }
    if (array.includes("vimeo.com") && vmId) {
      setItems((previous) => [...previous, { title: name, url, id: vmId }]);
      onTakeData({ title: name, url, id: vmId, isIcon });
      const htmlVimeo = `
      <iframe
      title="Vimeo Video"
      width="329px"
      height="288px"
      style={{ border: "1px solid black", borderRadius: "10px" }}
      src={"https://player.vimeo.com/video/{{id}}?autoplay=1"}
      allowFullScreen
    ></iframe>
      `;
      const emUrl = `https://player.vimeo.com/video/{{id}}?autoplay=1`;
      const htmltostr = htmlVimeo.replace(/"/g, '\\"').replace(/\n/g, "\\n").toString();
      dispatch(
        addWidget({
          name,
          type: "widget",
          widgetCat: "vimeo",
          widgetIcon: isIcon,
          url: emUrl.replace("{{id}}", vmId),
          html: htmltostr.replace(/\\"/g, '"').replace(/\\n/g, "\n").replace("{{id}}", vmId),
          folderId: "root",
          user: user._id,
        })
      );
      setName("");
      setUrl("");
      setItem({});
      setPreviewOpen(false);
      toast.success(t("views.dialogBoxVimeo.successfullyLoaded"));
      onClose();
    } else {
      setName("");
      setUrl("");
      toast.error(t("views.dialogBoxVimeo.onlyVimeoLinkValid"));
    }
  };

  const handlePreviewOpen = () => {
    const array = url.split("/");
    const vmId = array.pop();

    if (array.includes("vimeo.com") && vmId) {
      setItem({ title: name, url, id: vmId });
      toast.success(t("views.dialogBoxVimeo.successfullyLoaded"));
      setPreviewOpen(true);
    } else {
      toast.error(t("views.dialogBoxVimeo.errorCheck"));
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setItem({});
  };

  return (
    <>
      <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}>
          <img src={"./images/vimeoIcon.png"} alt="" style={{ width: "100px" }} />
        </div>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxVimeo.title")}</DialogTitle>
        <DialogContent sx={{ minHeight: "30vh" }}>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxVimeo.name")} &#8727;</DialogContentText>
            <TextField
              id="outlined-basic"
              label={t("views.dialogBoxVimeo.yourNameHere")}
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 3, width: "465px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxVimeo.url")} &#8727;</DialogContentText>
            <TextField
              id="outlined-basic"
              label={t("views.dialogBoxVimeo.url")}
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 3, width: "465px" }}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              alignItems: "center",
            }}>
            <DialogContentText sx={{ fontWeight: "bold" }}>{t("views.dialogBoxVimeo.mute")}: </DialogContentText>
            <Checkbox sx={{ marginLeft: 4 }} />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handlePreviewOpen}>
              {t("views.dialogBoxVimeo.preview")}
            </Button>
          </div>
          <div>
            <Button onClick={() => onClose(false)}>{t("views.dialogBoxVimeo.cancel")}</Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxVimeo.save")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}>
        {/* {items.map((item, index) => (
          <CardVimeo key={index} item={item} />
        ))} */}
      </div>

      <Dialog fullWidth open={previewOpen} onClose={handlePreviewClose}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}>
          <img src={"./images/vimeoIcon.png"} alt="" style={{ width: "100px" }} />
        </div>
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>{t("views.dialogBoxVimeo.title")}</DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#e5e3e3",
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
          }}>
          <div>
            <CardVimeo item={item} />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <div>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t("views.dialogBoxVimeo.confirm")}
            </Button>
          </div>
          <div>
            <Button onClick={handlePreviewClose}>{t("views.dialogBoxVimeo.cancel")}</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
