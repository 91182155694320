import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { baseImg } from "src/api/endpoints";
import ContentCard from "src/components/contentcard/ContentCard";
import ListViewFile from "./ListViewFile";

import { Divider, Box, TablePagination } from "@mui/material";

function Videos({
  content,
  onAddPlaylist,
  onPushScreen,
  onDelete,
  onMove,
  onPreview,
  view,
  onCheckedSingleContent,
  onUncheckedSingleContent,
  onUncheckedContent,
  handleSelectAll,
  onClose,
  idx,
  changeZoneDetails,
}) {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const contentVideos = content.filter((content) => content.type === "video");
  const contentToView = contentVideos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (handleSelectAll) {
      contentToView.forEach((c) => onCheckedSingleContent(c.id));
    }
  }, [handleSelectAll]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onUncheckedContent();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onUncheckedContent();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexWrap: "wrap",
          margin: "10px 0",
        }}>
        {view
          ? contentToView.map((data, index) => {
              if (data?.isActive) {
                return (
                  <ContentCard
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                  />
                );
              }
            })
          : contentToView.map((data, index) => {
              if (data?.isActive) {
                return (
                  <ListViewFile
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                    onClose={onClose}
                    idx={idx}
                    changeZoneDetails={changeZoneDetails}
                  />
                );
              }
            })}
      </Box>
      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={contentVideos.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.videos.rowsPerPage")}
      />
    </>
  );
}

export default Videos;
