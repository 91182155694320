import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useRef } from "react";
import Weather from "./Weather";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useMediaQuery } from "react-responsive";

import { InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box } from "@mui/material";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { addWidget } from "../../../redux/actions/Content";

export const renderThemeImage = (themeValue) => {
  const images = {
    bg1: `/images/BG_1.jpeg`,
    bg2: `/images/BG_2.jpeg`,
  };
  switch (themeValue) {
    case "#808080":
      return { bgImg: images.bg1, bgColor: "#808080" };
    case "#90EE90":
      return { bgImg: images.bg2, bgColor: "#90EE90" };
    default:
      return null;
  }
};

export default function DialogBoxWeather({ isOpen, onClose, isIcon }) {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [scale, setScale] = useState("");
  const [location, setLocation] = useState("");
  const [language, setLanguage] = useState("");
  const [font, setFont] = useState("");
  const [theme, setTheme] = useState("#808080");
  const [strip, setStrip] = useState("");
  const [name, setName] = useState("");
  const [wind, setWind] = useState("");
  const [previewTheme, setPreviewTheme] = useState("#808080");
  const [temperature, setTemperature] = useState(undefined);
  const [city, setCity] = useState("");
  const [pressure, setPressure] = useState(undefined);
  const [humidity, setHumidity] = useState(undefined);
  const [description, setDescription] = useState("");
  const [rain, setRain] = useState(undefined);
  const [icon, setIcon] = useState(undefined);
  const [error, setError] = useState("");
  const [forecast, setForecast] = useState([]);
  const dispatch = useDispatch();
  const { currentFolder } = useSelector((state) => state.FileFolder);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const { user } = useSelector((state) => state.User);

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const selectedPlaceRef = useRef();

  if (!isLoaded) {
    return (
      <>
        <h1>{t("views.dialogBoxWeather.loading")}</h1>
      </>
    );
  }

  const placeChanged = () => {
    const placeObject = selectedPlaceRef.current.getPlace();
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    getWeather(placeObject.name);
    setCity(placeObject.name);
    console.log("Latitude:", lat);
    console.log("Longitude:", lng);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
    setPreviewTheme(e.target.value);
  };

  const handleAdd = () => {
    setItems([
      ...items,
      {
        title: name,
        scale,
        location,
        language,
        font,
        theme,
        strip,
        wind,
        previewTheme,
        temperature,
        pressure,
        humidity,
        description,
        rain,
        icon,
        city,
        forecast,
      },
    ]);
    let subHtml = "";
    {
      forecast.map(
        (item) =>
          (subHtml += `<div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <h3>{{datetime}}</h3>
        <img src="{{iconW}}" alt="Not found" />
        <h1>
          <span>{{maxTemperature}}°</span>
          <span>{{minTemperature}}°</span>
        </h1>
      </div>`
            .replace("{{datetime}}", item.datetime)
            .replace("{{iconW}}", item.icon)
            .replace("{{maxTemperature}}", item.maxTemperature)
            .replace("{{minTemperature}}", item.minTemperature))
      );
    }

    let weatherHtml = `
    <div
      style="
        background-color: {{previewTheme}};
        background-image: url({{bgImgLink}});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        color: white;
        display: flex;
        justify-content: center;
        alignItems: center;
        flex-direction: column;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          flex-direction: column;
        "
      >
        <h1>{{city}}</h1>
        <img src="{{icon}}" alt="Not found" width="100" />
        <h1 style=" fontSize:70px;">{{temperature}}°C</h1>

        <div
          style="
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <div style=" 
            display: flex;
             align-items: center; 
             gap: 10px;
           "
           >
            
            <h3>{{humidity}}%</h3>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              gap: 10px;
              margin-left: 50px;
            "
          >
            
            <h3>{{pressure}} hPa</h3>
          </div>
        </div>
      </div>

      <div
        style="
          background-color: black;
          opacity:80%;
          height: 50vh;
          width: 100%;
          border-radius: 10px;
          margin-bottom: 60px;
          flex-direction:column;
          "
      >
        <div
          style="
            display: flex;
            justify-content: end;
            gap: 10px;
            margin-right: 50px;
          "
        >
          <p>{{momDD}}</p>
          <p>{{momLL}}</p>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 30px;
            "
        >
         {{weatherinfo}}
        </div>
      </div>
    </div>
    `
      .replace("{{weatherinfo}}", subHtml)
      .replace("{{previewTheme}}", theme)
      .replace("{{bgImgLink}}", renderThemeImage(theme).bgImg)
      .replace("{{city}}", city)
      .replace("{{temperature}}", temperature)
      .replace("{{humidity}}", humidity)
      .replace("{{pressure}}", pressure)
      .replace("{{momDD}}", moment().format("dddd"))
      .replace("{{momLL}}", moment().format("LL"))
      .replace("{{icon}}", icon);
    dispatch(
      addWidget({
        name,
        type: "widget",
        widgetCat: "weather",
        widgetIcon: isIcon,
        url: "",
        html: weatherHtml.replace(/\\"/g, '"').replace(/\\n/g, "\n"),
        folderId: currentFolder,
        user: user._id,
      })
    );
    setName("");
    setScale("");
    setFont("");
    setLocation("");
    setLanguage("");
    setTheme("#808080");
    setStrip("");
    setWind("");
    onClose();
    setCity("");
    setTemperature("");
    setPressure("");
    setHumidity("");
    setDescription("");
    setRain("");
    setIcon("");
    setForecast("");
  };

  const handleClose = () => {};

  const getWeather = async (city) => {
    if (city.trim() === "") {
      setError(t("views.dialogBoxWeather.enterCityName"));
      return;
    }

    const API_KEY = "b589b102afcfbd7027b38ea5b816f17a";

    const currentWeatherURL = `http://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API_KEY}&units=metric`;
    const currentWeatherResponse = await fetch(currentWeatherURL);
    const currentWeatherData = await currentWeatherResponse.json();

    const forecastURL = `http://api.openweathermap.org/data/2.5/forecast?q=${city}&appid=${API_KEY}&units=metric`;
    const forecastResponse = await fetch(forecastURL);
    const forecastData = await forecastResponse.json();

    if (currentWeatherData.cod === 200 && forecastData.cod === "200") {
      setTemperature(Math.round(currentWeatherData.main.temp));
      setPressure(currentWeatherData.main.pressure);
      setHumidity(currentWeatherData.main.humidity);
      setDescription(currentWeatherData.weather[0].description);
      setRain(currentWeatherData.rain ? currentWeatherData.rain["1h"] : null);
      setIcon(`http://openweathermap.org/img/w/${currentWeatherData.weather[0].icon}.png`);

      const forecastList = forecastData.list;
      const forecastItems = [];
      let currentDate = null;

      for (const item of forecastList) {
        const forecastDate = new Date(item.dt_txt).toLocaleDateString();
        if (currentDate !== forecastDate) {
          const forecastDatee = new Date(item.dt_txt);
          const options = { day: "numeric", weekday: "short" };
          const formattedDate = forecastDatee.toLocaleDateString("en-US", options);

          let minTemp = Number.MAX_SAFE_INTEGER;
          let maxTemp = Number.MIN_SAFE_INTEGER;

          for (const forecastItem of forecastList) {
            const itemDate = new Date(forecastItem.dt_txt).toLocaleDateString();
            if (itemDate === forecastDate) {
              const temperature = Math.round(forecastItem.main.temp);
              minTemp = Math.min(minTemp, temperature);
              maxTemp = Math.max(maxTemp, temperature);
            }
          }

          const forecastItem = {
            datetime: formattedDate,
            minTemperature: minTemp,
            maxTemperature: maxTemp,
            pressure: item.main.pressure,
            humidity: item.main.humidity,
            description: item.weather[0].description,
            rain: item.rain ? item.rain["1h"] : null,
            icon: `http://openweathermap.org/img/w/${item.weather[0].icon}.png`,
          };

          forecastItems.push(forecastItem);
          currentDate = forecastDate;
        }
      }

      setForecast(forecastItems);

      setError("");
    } else {
      setTemperature(null);
      setPressure(null);
      setHumidity(null);
      setDescription("");
      setRain(null);
      setIcon("");
      setForecast([]);
      setError(t("views.dialogBoxWeather.cityNotFound"));
    }
  };

  if (!isLoaded) {
    return <>Loading</>;
  }
  return (
    <div>
      <Dialog open={isOpen} onClose={() => onClose(false)} sx={{ zIndex: "1000" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
          }}>
          <img src="/images/weather-widget.png" />
        </div>
        <DialogTitle sx={{ textAlign: "center" }}>{t("views.dialogBoxWeather.weather")}</DialogTitle>
        <DialogContent sx={{ px: 10, maxHeight: isMobile ? "200px" : "400px", maxWidth: isMobile ? "360px" : "600px", padding: "20px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="outlined-basic"
            label={t("views.dialogBoxWeather.name")}
            type="text"
            fullWidth
            size="small"
            variant={"standard"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <br />
          <FormControl fullWidth variant="standard" size="small">
            <InputLabel id="demo-simple-select-label">{t("views.dialogBoxWeather.scale")}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Fahrenhite" value={scale} onChange={(e) => setScale(e.target.value)}>
              <MenuItem value={"Fahrenheit"}>{t("views.dialogBoxWeather.fahrenheit")}</MenuItem>
              <MenuItem value={"Celsius"}>{t("views.dialogBoxWeather.celsius")}</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel required control={<Checkbox />} sx={{ margin: "20px -10px" }} label={t("views.dialogBoxWeather.useDeviceLocation")} />
          <Box sx={{ zIndex: "1000000000000000" }}>
            <Autocomplete onLoad={(autocomplete) => (selectedPlaceRef.current = autocomplete)} onPlaceChanged={placeChanged}>
              <TextField variant="standard" type={"text"} sx={{ width: "100%" }} placeholder={t("views.dialogBoxWeather.location")} className="custom-input" />
            </Autocomplete>
          </Box>
          <br />
          <FormControl
            fullWidth
            size="small"
            variant="standard"
            // sx={{ borderBottom: "1px solid blue" }}
          >
            <InputLabel id="demo-simple-select-label">{t("views.dialogBoxWeather.selectLanguage")}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Fahrenhite" value={language} onChange={(e) => setLanguage(e.target.value)}>
              <MenuItem value={"English"}>{t("views.dialogBoxWeather.english")}</MenuItem>
              <MenuItem value={"Spanish"}>{t("views.dialogBoxWeather.spanish")}</MenuItem>
              <MenuItem value={"Urdu"}>{t("views.dialogBoxWeather.urdu")}</MenuItem>
              <MenuItem value={"Arabic"}>{t("views.dialogBoxWeather.arabic")}</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel required sx={{ margin: "20px -10px" }} control={<Checkbox />} label={t("views.dialogBoxWeather.hoursFormat")} /> <br />
          <FormControl
            fullWidth
            size="small"
            variant="standard"
            // sx={{ borderBottom: "1px solid blue" }}
          >
            <InputLabel id="demo-simple-select-label">{t("views.dialogBoxWeather.selectFontSize")}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Fahrenhite" value={font} onChange={(e) => setFont(e.target.value)}>
              <MenuItem value={"S"}>{t("views.dialogBoxWeather.sizeS")}</MenuItem>
              <MenuItem value={"L"}>{t("views.dialogBoxWeather.sizeL")}</MenuItem>
              <MenuItem value={"XL"}>{t("views.dialogBoxWeather.sizeXL")}</MenuItem>
              <MenuItem value={"XXL"}>{t("views.dialogBoxWeather.sizeXXL")}</MenuItem>
            </Select>
          </FormControl>
          <br /> <br />
          <FormControl variant="standard" fullWidth size="small">
            <InputLabel id="theme-select-label">{t("views.dialogBoxWeather.selectTheme")}</InputLabel>
            <Select labelId="theme-select-label" id="theme-select" value={theme} onChange={handleThemeChange} label="Theme">
              <MenuItem value="#808080">{t("views.dialogBoxWeather.black")}</MenuItem>
              <MenuItem value="#90EE90">{t("views.dialogBoxWeather.yellow")}</MenuItem>
            </Select>
          </FormControl>
          <div></div>
          <h5>{t("views.dialogBoxWeather.preview")}</h5>
          <div
            style={{
              backgroundColor: theme,
              backgroundImage: `url(${renderThemeImage(theme).bgImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: isMobile ? "100%" : "423px",
              marginTop: "20px",
              color: "white",
            }}>
            {error ? (
              <div className="error">{error}</div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}>
                  <p style={{ marginBottom: "0" }}>{city}</p>
                  <Weather icon={icon} />
                  <span style={{ fontSize: "30px" }}>
                    <Weather temperature={temperature} />
                  </span>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    <img src={"/images/Humidity.png"} style={{ width: "20px", height: "20px" }} alt="Not found" />
                    <Weather humidity={humidity} />
                    <img
                      src={"/images/Air_Pressure.png"}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "20px",
                      }}
                      alt="Not found"
                    />
                    <Weather pressure={pressure} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <div
                    style={{
                      backgroundColor: "black",
                      opacity: "80%",
                      height: "160px",
                      width: "90%",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}>
                      {forecast.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}>
                          {forecast.map((item, index) => (
                            <div key={index}>
                              <p>{item.datetime}</p>
                              {item.icon && <img src={item.icon} alt="Weather Icon" />}
                              <p>
                                <span>{item.maxTemperature}°</span> <span>{item.minTemperature}°</span>{" "}
                              </p>
                              <p></p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br />
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="demo-simple-select-label">{t("views.dialogBoxWeather.stripFontSize")}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Fahrenhite" value={strip} onChange={(e) => setStrip(e.target.value)}>
              <MenuItem value={"S"}>{t("views.dialogBoxWeather.sizeS")}</MenuItem>
              <MenuItem value={"L"}>{t("views.dialogBoxWeather.sizeL")}</MenuItem>
              <MenuItem value={"MD"}>{t("views.dialogBoxWeather.sizeMD")}</MenuItem>
              <MenuItem value={"xl"}>{t("views.dialogBoxWeather.sizeXL")}</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="demo-simple-select-label">{t("views.dialogBoxWeather.windSpeed")}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Fahrenhite" value={wind} onChange={(e) => setWind(e.target.value)}>
              <MenuItem value={"Fast"}>{t("views.dialogBoxWeather.fast")}</MenuItem>
              <MenuItem value={"Medium"}>{t("views.dialogBoxWeather.medium")}</MenuItem>
              <MenuItem value={"Slow"}>{t("views.dialogBoxWeather.slow")}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            marginTop: 1,
          }}>
          <Button variant="outline" color="primary" onClick={() => onClose(false)}>
            {t("views.dialogBoxWeather.cancel")}
          </Button>

          <Button variant="contained" color="primary" onClick={handleAdd}>
            {t("views.dialogBoxWeather.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
