import React, { useEffect, useRef, useMemo, useCallback } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { FitScreen, Orientation, ContentType } from "./types";
import SingleZone from "./SingleZone";
import { useSettings } from "src/hooks/use-settings";
import { useTranslation } from "react-i18next";

const ZoneDetailsControl = ({
  setSplitScreenSaved,
  playlists,
  settingsInfo,
  selectedId,
  screenInfo,
  changeScreenInfo,
  zones,
  changeZoneDetails,
  stageSize,
}) => {
  const { t } = useTranslation();
  var selected_idx = -100;
  const zoneRefs = useRef([]);
  const settings = useSettings();
  // console.log("Hello screenInfo", screenInfo);

  // Używamy useEffect, aby przewijać do wybranego zone po zmianie selectedId
  useEffect(() => {
    if (zones && zones.length > 0 && selectedId) {
      setTimeout(() => {
        const selectedZoneIndex = zones.findIndex(
          (zone) => zone.id === selectedId
        );
        if (selectedZoneIndex !== -1 && zoneRefs.current[selectedZoneIndex]) {
          zoneRefs.current[selectedZoneIndex].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 0);
    }
  }, [selectedId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "auto",
        maxHeight: "calc(100vh - 193px)", // Ogólnie wychodzi mi 64px + 40px + 32px+5px = 141px  + footer
        paddingRight: "10px",
        paddingBottom: "30px",
      }}
    >
      <div
        style={{
          paddingTop: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          width: "100%",
        }}
      >
        <TextField
          label={`${t("zoneDetailsControl.name_label")} *`}
          variant="outlined"
          value={screenInfo.name}
          onChange={(e) => changeScreenInfo("name", e.target.value)}
          fullWidth
        />

        <FormControl fullWidth variant="outlined">
          <InputLabel id="select-label">
            {t("zoneDetailsControl.orientation_label")}
          </InputLabel>
          <Select
            labelId="select-label"
            value={screenInfo.orientation}
            onChange={(e) => changeScreenInfo("orientation", e.target.value)}
            label={t("zoneDetailsControl.orientation_label")}
          >
            {Object.entries(Orientation).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {t(`zoneDetailsControl.orientation_options.${key}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        {zones &&
          zones?.length > 0 &&
          zones.map((zone, index) => {
            if (selectedId == zone.id) {
              selected_idx = index;
            }
            return (
              <div
                ref={(el) => (zoneRefs.current[index] = el)}
                key={zone.id}
                style={{
                  backgroundColor:
                    selectedId == zone.id
                      ? settings.paletteMode == "light"
                        ? "#F0F0F0"
                        : "#1A2432"
                      : settings.paletteMode == "light"
                      ? "white"
                      : "#111927",
                  paddingBottom: selectedId == zone.id ? "30px" : "0px",
                }}
              >
                <div
                  style={{
                    height: "1px",
                    backgroundColor:
                      selectedId == zone.id ? zone.fill : "white",
                    width: "100%",
                    margin: "auto",
                    marginTop: index == selected_idx + 1 ? "0px" : "30px",
                  }}
                ></div>
                <div
                  style={{
                    height: "9px",
                    backgroundColor:
                      selectedId == zone.id ? zone.fill : "#111927",
                    width: "100%",
                    margin: "auto",
                    marginBottom: "30px",
                  }}
                ></div>

                <SingleZone
                  setSplitScreenSaved={setSplitScreenSaved}
                  playlists={playlists}
                  settingsInfo={settingsInfo}
                  zone={zone}
                  changeZoneDetails={changeZoneDetails}
                  idx={index}
                  stageSize={stageSize}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ZoneDetailsControl;
