import React, {useState, useRef, useEffect} from 'react'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Divider
  } from "@mui/material";

import FileFolder from './FileFolder';

const DialogBoxAssets = ({isOpen, onClose, idx, changeZoneDetails}) => {
    const socketRef = useRef();
    const { user } = useSelector((state) => state.User);



  return (
    <Dialog
    open={isOpen}
    onClose={() => onClose(false)}
    fullWidth
    maxWidth="xl"
    >
        <DialogTitle sx={{marginBottom:"10px"}}>
            <Stack direction={"column"} sx={{alignItems:"center"}}>
                <Box fontSize="h4.fontSize">Set Assets</Box>
            </Stack>
        </DialogTitle>
        <Divider sx={{ width: '100%', marginTop:"20px" }} />

        <DialogContent>
      <FileFolder user={user} socketRef={socketRef} onClose={onClose} idx={idx} changeZoneDetails={changeZoneDetails}/>
        </DialogContent>
    </Dialog>
  )
}

export default DialogBoxAssets