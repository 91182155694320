import { COLORS } from "./types";
let usedColors = [];

export const getRandomColor = () => {
  if (usedColors.length === COLORS.length) {
    usedColors = [];
  }
  
  let availableColors = COLORS.filter(color => !usedColors.includes(color));
  const randomIndex = Math.floor(Math.random() * availableColors.length);
  const selectedColor = availableColors[randomIndex];
  
  usedColors.push(selectedColor);
  return selectedColor;
};