import { Box } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import TvIcon from "@mui/icons-material/Tv";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import FolderIcon from "@mui/icons-material/Folder";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { WidgetsGrid } from "../../components/widgetsGrid/WidgetsGrid.jsx";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import RedesignCard from "../../components/card/RedesignCard";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-hook-form";
import { getAllScreenById } from "../../redux/actions/Screen";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import Circularbar from "../../components/circularbar/Circularbar";

import { useSettings } from "src/hooks/use-settings";
import BreadCrumbs from "src/components/common/BreadCrumbs.jsx";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.warning.main],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      horizontalAlign: "right",
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: "top",
      show: true,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 3],
      lineCap: "butt",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan", "07 Jan", "08 Jan", "09 Jan", "10 Jan", "11 Jan", "12 Jan"],
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        opposite: true,
      },
    ],
  };
};

function Dashboard({ user, socketRef }) {
  const { t } = useTranslation();
  const chartOptions = useChartOptions();
  const settings = useSettings();
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const isCellPhone = useMediaQuery({ query: "(max-width:480px)" });
  const [isMobiles, setIsMobiles] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const dispatch = useDispatch();

  const { filesCount, groupsCount, playlistCount, screensCount } = useSelector((s) => ({
    filesCount: s.Content.content.length,
    groupsCount: s.Group?.userGroups?.length ?? 0,
    playlistCount: s.Playlist.playlists.filter(p => p.content[0]?.purpose !== "asset").length,
    screensCount: s.Screen.screens.length,
  }));

  useEffect(() => {
    // console.log(isMobile,isCellPhone)
    window.addEventListener("resize", function (e) {
      e.target.innerWidth == "480" && setIsMobiles(true);
      e.target.innerWidth == "768" && setIsTab(true);
      // console.log(e.target.innerWidth);
    });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const { screens, isScreenRegistered, mac, activeScreens, deactiveScreens } = useSelector((state) => state.Screen);
  const { content, isContentAddedInContent, isContentAdding, lengthOfContent } = useSelector((state) => state.Content);

  const navigate = useNavigate();
  const macRef = useRef();

  useEffect(() => {
    console.log("useeffect!!!");
    if (user.isMember) {
      dispatch(getAllScreenById(user?.user._id));
    } else {
      dispatch(getAllScreenById(user?._id));
    }
  }, []);

  return (
    <>
      <Layout title={"asdasd"}>
        {isContentAdding && (
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              position: "absolute",
              top: 0,
              // left: 0,
              bottom: 0,
              right: 0,
              zIndex: 10000000000000,
              background: "rgba(0,0,0,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Circularbar />
          </Box>
        )}
        <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            {/* <BreadCrumbs title={t("layout.sideNav.dashboard")} /> */}
            <Grid container spacing={4}>
              <Grid xs={6} md={3}>
                <RedesignCard
                  buttonComponent={RouterLink}
                  buttonTo={"/files"}
                  cardTitle={t("views.redesignedDashboard.files")}
                  amount={filesCount}
                  icon={<FileUploadIcon fontSize="large" color="primary" />}
                  moreButton={t("views.redesignedDashboard.addFile")}
                  buttonIcon={<AddIcon />}
                />
              </Grid>
              <Grid xs={6} md={3}>
                <RedesignCard
                  buttonComponent={RouterLink}
                  buttonTo={"/users"}
                  cardTitle={t("views.redesignedDashboard.groups")}
                  amount={groupsCount}
                  icon={<FolderIcon fontSize="large" color="primary" />}
                  moreButton={t("views.redesignedDashboard.addGroup")}
                  buttonIcon={<AddIcon />}
                  feature={"users_group_management"}
                />
              </Grid>
              <Grid xs={6} md={3}>
                <RedesignCard
                  buttonComponent={RouterLink}
                  buttonTo={"/playlist"}
                  cardTitle={t("views.redesignedDashboard.playlists")}
                  amount={playlistCount}
                  icon={<SubscriptionsIcon fontSize="large" color="primary" />}
                  moreButton={t("views.redesignedDashboard.addPlaylist")}
                  buttonIcon={<AddIcon />}
                />
              </Grid>
              <Grid xs={6} md={3}>
                <RedesignCard
                  buttonComponent={RouterLink}
                  buttonTo={"/screen"}
                  cardTitle={t("views.redesignedDashboard.screens")}
                  amount={screensCount}
                  icon={<TvIcon fontSize="large" color="primary" />}
                  moreButton={t("views.redesignedDashboard.addScreen")}
                  buttonIcon={<AddIcon />}
                />
              </Grid>

              <Grid xs={12}>
                <WidgetsGrid name={"dashboard"} title={t("views.redesignedDashboard.dashboardWidgets")} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Layout>
    </>
  );
}

export default Dashboard;
