import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { baseImg } from "../../../api/endpoints";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import RedesignBodyHeader from "./RedesignBodyHeader";
import Folders from "./Folders";
function SelectFilesModal({
  open,
  setOpenSelectFiles,
  files,
  onSaveFiles,
  playlist,
  singleSelect = false,
}) {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToSkip, setFilesToSkip] = useState([]);
  const { userFolders, currentFolder } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      userFolders: state.FileFolder.userFolders.filter(
        (f) =>
          f.parent === state.FileFolder.currentFolder &&
          f?.folderFor === "content"
      ),
    }),
    shallowEqual
  );

  useEffect(() => {
    let filesToExclude = [];
    if (playlist && playlist.content) {
      playlist.content.forEach((item) => {
        if (item.content) {
          filesToExclude.push(item.content._id);
        }
      });
      setFilesToSkip(filesToExclude);
    }
  }, [open]);

  const handleSelectFile = (id) => {
    if (singleSelect) {
      setSelectedFiles([id]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.includes(id)
          ? prevSelectedFiles.filter((fileId) => fileId !== id)
          : [...prevSelectedFiles, id]
      );
    }
  };

  const handleUnselectFile = (id) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((item) => item !== id)
    );
  };

  const handleSaveFiles = () => {
    onSaveFiles(selectedFiles);
    setOpenSelectFiles(false);
    setSelectedFiles([]);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpenSelectFiles(false)}
        maxWidth={"xl"}
        fullWidth={true}
        sx={{ zIndex: "10000000000" }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {t("views.selectFilesModal.selectFiles")}
        </DialogTitle>
        <RedesignBodyHeader />
        {/* List of folders */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            margin: "20px 0 10px 25px",
          }}
        >
          {userFolders.map((folder) => (
            <Folders key={folder._id} folder={folder} />
          ))}
        </Box>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ margin: "20px 0" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>{t("views.selectFilesModal.preview")}</TableCell>
                    <TableCell>{t("views.selectFilesModal.type")}</TableCell>
                    <TableCell>
                      {t("views.selectFilesModal.fileName")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files
                    .filter(
                      (file) =>
                        !filesToSkip.includes(file.id) &&
                        file.folderId === currentFolder // Filter files by currentFolder
                    )
                    .map((file) => (
                      <TableRow hover key={file.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedFiles.includes(file.id)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                handleSelectFile(file.id);
                              } else {
                                handleUnselectFile(file.id);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ height: "60px", width: "108px" }}>
                            {file.type === "image" && (
                              <img
                                src={`${baseImg}/${file.url}`}
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                  margin: "auto",
                                  display: "block",
                                }}
                              />
                            )}
                            {(file.type === "widget" || file.type === "split_screen" ) && (
                              <img
                                src={`${file.widget?.widgetIcon}`}
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                  margin: "auto",
                                  display: "block",
                                }}
                              />
                            )}
                            
                            {file.type === "video" && (
                              <video
                                src={`${baseImg}/${file.url}`}
                                controls
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                }}
                              />
                            )}
                            {file.embed === "embed" && (
                              <embed
                                src={`${file.url}`}
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                }}
                              />
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {file.type === "image" && <ImageIcon />}
                          {file.type === "widget" && <AppRegistrationIcon />}
                          {file.type === "video" && <SlowMotionVideoIcon />}
                          {file.embed === "embed" && <DataObjectIcon />}
                        </TableCell>
                        <TableCell>
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={1}
                          >
                            <Typography color="text.secondary" variant="body2">
                              {file.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            sx={{
              height: "38px",
              width: "193px",
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
            onClick={() => setOpenSelectFiles(false)}
          >
            {t("views.selectFilesModal.close")}
          </Button>
          <Button
            variant={"contained"}
            sx={{
              height: "38px",
              width: "193px",
              textTransform: "capitalize",
              borderRadius: "5px",
            }}
            onClick={handleSaveFiles}
          >
            {t("views.selectFilesModal.add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SelectFilesModal;
