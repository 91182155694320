import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { baseImg } from "../../../api/endpoints";
import { useMediaQuery } from "react-responsive";
function Previewer({ previewContentUrl }) {
  const [resolution, setResolution] = useState("calc((50vh - 100px) * 16 / 9)");
  const isCellPhone = useMediaQuery({ query: "(max-width:480px)" });
  useEffect(() => {
    isCellPhone && setResolution("calc(200px * 9 / 16)");
    if (previewContentUrl.type == "widget" && previewContentUrl.widget.widgetCat == "scrollingstrip") {
      const htmlCode = document.querySelector("#scrollingstrip");
      htmlCode.innerHTML = previewContentUrl.widget.url;
    }
  }, [isCellPhone, previewContentUrl]);

  return (
    <>
      <Box
        sx={{
          height: "calc(50vh - 100px)",
          width: `100%`,
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {previewContentUrl.type === "image" && <img src={`${baseImg}/${previewContentUrl.url}`} style={{ height: "100%", width: "100%", objectFit: "contain" }} />}
        {previewContentUrl.type === "video" && (
          <video src={`${baseImg}/${previewContentUrl.url}`} style={{ height: "100%", width: "100%", objectFit: "contain" }} controls></video>
        )}
        {previewContentUrl.embed === "embed" && <embed src={`${previewContentUrl.url}`} style={{ height: "100%", width: "100%", objectFit: "contain" }} controls />}
        {previewContentUrl.type === "widget" && (
          <>
            {previewContentUrl?.widget?.widgetCat === "youtube" && (
              <div style={{ height: "100%", width: "100%" }} id="youtube-widget">
                <iframe src={previewContentUrl.widget.url} style={{ height: "100%", width: "100%" }}></iframe>
              </div>
            )}

            {previewContentUrl?.widget?.widgetCat === "vimeo" && (
              <div style={{ height: "100%", width: "100%" }}>
                <iframe src={previewContentUrl.widget.url} style={{ height: "100%", width: "100%" }}></iframe>
              </div>
            )}
            {previewContentUrl.widget.widgetCat === "website" && <iframe src={previewContentUrl.widget.url} style={{ height: "100%", width: "100%" }}></iframe>}
            {previewContentUrl.widget.widgetCat === "scrollingstrip" && <div style={{ height: "100%", width: "100%" }} id="scrollingstrip"></div>}
            {previewContentUrl.widget.widgetCat === "embed" && (
              <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: previewContentUrl.widget.html }}></div>
            )}

            {previewContentUrl.widget.widgetCat === "weather" && (
              <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: previewContentUrl.widget.html }}></div>
            )}

            {previewContentUrl.widget.widgetCat === "canva" && (
              <div
                style={{ height: "100%", width: "100%", maxHeight: "100%", overflow: "auto" }}
                dangerouslySetInnerHTML={{ __html: previewContentUrl.widget.html.replaceAll(">\\n", "<br/>").replaceAll("\\", "") }}></div>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default Previewer;
