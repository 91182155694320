import { Box } from "@mui/material";
import React from "react";
import { baseImg } from "../../../api/endpoints";
function FileItem({ files }) {
  return (
    <>
      <Box sx={{ height: "100px", width: "100px", border: "1px solid black" }}>
        <img src={`${baseImg}/${files.url}`} style={{ height: "100%", width: "100%" }} />
      </Box>
    </>
  );
}

export default FileItem;
