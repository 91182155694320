import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack, Button, SvgIcon, Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

import Download01Icon from "@untitled-ui/icons-react/build/esm/Download01";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import Upload01Icon from "@untitled-ui/icons-react/build/esm/Upload01";

import { paths } from "src/paths";

const RedesignUserListHeader = ({ currentGroup, groups, onAddGroup, onAddUsers }) => {
  const { t } = useTranslation();

  const breadcrumbs = useMemo(() => {
    const getParentGroups = (group, list = []) => {
      if (group) list.unshift(group);

      if (!group?.parent) return list;

      let parentGroup = groups.find((g) => g._id === group.parent);
      return getParentGroups(parentGroup, list);
    };

    let list = [
      { text: t("views.redesignUserListHeader.home"), to: paths.users.index },
      ...getParentGroups(currentGroup).map((g) => ({
        text: g.name,
        to: `${paths.users.index}/${g._id}`,
      })),
    ];

    return list;
  }, [groups, currentGroup, t]);

  const handleAddGroupClick = useCallback(() => {
    onAddGroup?.();
  }, [onAddGroup]);

  const handleAddUsersClick = useCallback(() => {
    onAddUsers?.();
  }, [onAddUsers]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={4}>
      <Stack spacing={2}>
        {/* <Typography variant="h4">{t("views.redesignUserListHeader.users")}</Typography> */}
        <div role="presentation">
          <Breadcrumbs separator={<Box sx={{ backgroundColor: "neutral.500", borderRadius: "50%", height: 4, width: 4 }} />}>
            {breadcrumbs.map((b, idx) => (
              <Button key={`breadcrumbs_${idx}`} variant="link" display="block" component={Link} to={b.to}>
                {b.text}
              </Button>
            ))}
          </Breadcrumbs>
        </div>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Button
            disabled={true}
            color="inherit"
            size="small"
            startIcon={
              <SvgIcon>
                <Upload01Icon />
              </SvgIcon>
            }>
            {t("views.redesignUserListHeader.import")}
          </Button>
          <Button
            disabled={true}
            color="inherit"
            size="small"
            startIcon={
              <SvgIcon>
                <Download01Icon />
              </SvgIcon>
            }>
            {t("views.redesignUserListHeader.export")}
          </Button>
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={3}>
        {currentGroup !== null && (
          <Button
            variant="contained"
            onClick={handleAddUsersClick}
            startIcon={
              <SvgIcon>
                <PlusIcon />
              </SvgIcon>
            }>
            {t("views.redesignUserListHeader.addUsers")}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleAddGroupClick}
          startIcon={
            <SvgIcon>
              <PlusIcon />
            </SvgIcon>
          }>
          {t("views.redesignUserListHeader.addGroup")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default RedesignUserListHeader;
