/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie";

import en from "./translations/en.json";
import de from "./translations/de.json";
import es from "./translations/es.json";
import pl from "./translations/pl.json";

const savedLanguage = Cookies.get("language") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    de: { translation: de },
    es: { translation: es },
    pl: { translation: pl },
  },
  lng: savedLanguage,
  fallbackLng: "en",
  detection: {
    order: ["cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
    caches: ["cookie"],
  },
  interpolation: {
    escapeValue: false,
  },
});
